import React, { useState, useRef } from "react";
import Card from "../components/CardProfile";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { IconContext } from "react-icons";
import { Link, useNavigate } from "react-router-dom";
import { SidebarData } from "./SidebarData";
import useAuth from "../hooks/useAuth";
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import { GiGrapes } from "react-icons/gi";

export default function Navbar() {
  const [sidebar, setSidebar] = useState(false);
  const { logout } = useAuth();
  const showSidebar = () => setSidebar(!sidebar);
  const op = useRef(null);
  const navigate = useNavigate();
  const nomUser = sessionStorage.getItem('nom_user');

  return (
    <>
      <IconContext.Provider value={{ color: "#FFF" }}>
        <div className="navbar bg-black text-white flex items-center justify-between px-4 py-2 shadow-lg z-[1000]">
          <Link to="#" className="menu-bars">
            <FaIcons.FaBars onClick={showSidebar} className="text-2xl hover:scale-110 transition-transform duration-300" />
          </Link>
          <h1
            className="navbar-title cursor-pointer text-2xl font-bold transition-colors duration-300 hover:text-yellow-400"
            onClick={() => navigate('/')}
          >
            Vin sur vin
          </h1>

          <Button
            className="bg-black p-2 rounded-full text-2xl hover:bg-yellow-600 transition-colors duration-300"
            type="button"
            onClick={(e) => op.current.toggle(e)}
          >
            <GiGrapes />
          </Button>
          <OverlayPanel ref={op}>
            <Card nomComplet={nomUser} />
          </OverlayPanel>
        </div>

        <nav
          className={`fixed top-0 left-0 h-full w-64 bg-[#111] text-white z-[1000] transform transition-transform duration-500 ease-in-out ${sidebar ? "translate-x-0" : "-translate-x-full"
            } overflow-y-auto`}
        >
          <ul className="nav-menu-items p-4 space-y-4" onClick={showSidebar}>
            <li className="navbar-toggle">
              <Link to="#" className="menu-bars">
                <AiIcons.AiOutlineClose className="text-white text-2xl hover:rotate-90 transition-transform duration-300" />
              </Link>
            </li>

            {SidebarData.map((section, index) => (
              <div key={index}>
                <h3 className="nav-section-title text-gray-400 mt-4 mb-2 text-sm uppercase tracking-wide animate-fade-in">
                  {section.section}
                </h3>
                {section.items.map((item, idx) => (
                  <li
                    key={idx}
                    className={`${item.cName} transition-transform duration-300 hover:translate-x-2`}
                  >
                    <Link
                      to={item.path}
                      className="flex items-center space-x-3 text-white hover:text-yellow-400 transition-colors duration-300"
                    >
                      {item.icon}
                      <span className="spanNav">{item.title}</span>
                    </Link>
                  </li>
                ))}
              </div>
            ))}
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
}

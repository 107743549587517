import React from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import '../styles/VinTabs.css'; // Importer le fichier CSS personnalisé
import VinDegustation from '../components/VinDegustation';

const VinTabs = ({ vin, isEditing, handleInputChange }) => {
  return (
    <TabView className="no-tailwind tex-sm md:text-lg">
      <TabPanel header="Cave" leftIcon="pi pi-calendar mr-2" className='text-sm md:text-lg'>
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 gap-4">
          <div>
            <label className="block text-gray-700 font-bold text-sm md:text-base">Lieu d'Achat:</label>
            <input
              type="text"
              name="Lieu_Achat"
              value={vin.Lieu_Achat}
              onChange={handleInputChange}
              readOnly={!isEditing}
              className="w-full p-1 md:p-2 border border-gray-300 rounded-md bg-gray-100 text-sm md:text-base"
            />
          </div>
          <div>
            <label className="block text-gray-700 font-bold text-sm md:text-base">Date d'Achat:</label>
            <input
              type="date"
              name="Date_Achat"
              value={vin.Date_Achat}
              onChange={handleInputChange}
              readOnly={!isEditing}
              className="w-full p-1 md:p-2 border border-gray-300 rounded-md bg-gray-100 text-sm md:text-base"
            />
          </div>
          <div>
            <label className="block text-gray-700 font-bold text-sm md:text-base">Prix d'Achat:</label>
            <input
              type="number"
              name="Prix_Achat"
              value={vin.Prix_Achat}
              onChange={handleInputChange}
              readOnly={!isEditing}
              className="w-full p-1 md:p-2 border border-gray-300 rounded-md bg-gray-100 text-sm md:text-base"
            />
          </div>
          <div>
            <label className="block text-gray-700 font-bold text-sm md:text-base">Quantité:</label>
            <input
              type="number"
              name="Qte"
              value={vin.Qte}
              onChange={handleInputChange}
              readOnly={!isEditing}
              className="w-full p-1 md:p-2 border border-gray-300 rounded-md bg-gray-100 text-sm md:text-base"
            />
          </div>
          <div>
            <label className="block text-gray-700 font-bold text-sm md:text-base">Dont Bue:</label>
            <input
              type="text"
              name="Dont_Bue"
              value={vin.Dont_Bue}
              onChange={handleInputChange}
              readOnly={!isEditing}
              className="w-full p-1 md:p-2 border border-gray-300 rounded-md bg-gray-100 text-sm md:text-base"
            />
          </div>
          <div>
            <label className="block text-gray-700 font-bold text-sm md:text-base">Reste en Cave:</label>
            <input
              type="text"
              name="Reste_En_Cave"
              value={vin.Reste_En_Cave}
              onChange={handleInputChange}
              readOnly={!isEditing}
              className="w-full p-1 md:p-2 border border-gray-300 rounded-md bg-gray-100 text-sm md:text-base"
            />
          </div>
          <div>
            <label className="block text-gray-700 font-bold text-sm md:text-base">Valeur:</label>
            <input
              type="number"
              name="Valeur"
              value={vin.Valeur}
              onChange={handleInputChange}
              readOnly={!isEditing}
              className="w-full p-1 md:p-2 border border-gray-300 rounded-md bg-gray-100 text-sm md:text-base"
            />
          </div>
          <div>
            <label className="block text-gray-700 font-bold text-sm md:text-base">Plus-Value (EUR):</label>
            <input
              type="text"
              name="Plus_Value_Eur"
              value={vin.Plus_Value_Eur}
              onChange={handleInputChange}
              readOnly={!isEditing}
              className="w-full p-1 md:p-2 border border-gray-300 rounded-md bg-gray-100 text-sm md:text-base"
            />
          </div>
          <div>
            <label className="block text-gray-700 font-bold text-sm md:text-base">Apogée:</label>
            <input
              type="number"
              name="Apogee"
              value={vin.Apogee}
              onChange={handleInputChange}
              readOnly={!isEditing}
              className="w-full p-1 md:p-2 border border-gray-300 rounded-md bg-gray-100 text-sm md:text-base"
            />
          </div>
          <div>
            <label className="block text-gray-700 font-bold text-sm md:text-base">Apogée max:</label>
            <input
              type="number"
              name="Apogee_Max"
              value={vin.Apogee_Max}
              onChange={handleInputChange}
              readOnly={!isEditing}
              className="w-full p-1 md:p-2 border border-gray-300 rounded-md bg-gray-100 text-sm md:text-base"
            />
          </div>
        </div>
      </TabPanel>
      <TabPanel header="Dégustation" rightIcon="pi pi-user ml-2" className='text-sm md:text-lg'>
        <VinDegustation vin={vin} isEditing={isEditing} handleInputChange={handleInputChange} />
      </TabPanel>
      <TabPanel header="Producteur" leftIcon="pi pi-search mr-2" rightIcon="pi pi-cog ml-2" className='text-sm md:text-lg'>
        <p className="m-0">
        En cours..
        </p>
      </TabPanel>
    </TabView>
  );
};

export default VinTabs;
import React, { useState } from "react";
import { TextField, Button, Typography, Link, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import authHeader from '../config/authHeader';
import config from '../config/config';

const Inscription = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [nom, setNom] = useState("");
    const [prenom, setPrenom] = useState("");
    const [statut, setStatut] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setError("Les mots de passe ne correspondent pas !");
            return;
        }
        if (!email || !password || !nom || !prenom) {
            setError("Veuillez remplir tous les champs !");
            return;
        }
        try {
            // Simuler un POST vers une API d'inscription
            const formData = new FormData();
            formData.append("email", email);
            formData.append("pass", password);
            formData.append("nom", nom);
            formData.append("prenom", prenom);
            formData.append("statut", statut);
            const response = await fetch(`${config.apiBaseUrl}/4DACTION/react_inscription`, {
                //const response = await fetch(`/4DACTION/react_inscription`, {
                method: 'POST',  // ou 'PATCH' selon l'API
                headers: authHeader(),
                body: formData,
            });

            if (!response.ok) {
                throw new Error("Erreur lors de l'inscription");
            }
            //récupérer la réponse texte
            //const text = await response.text();

            // Rediriger vers la page de connexion après une inscription réussie
            navigate("/login");
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <div className="login-container pageSidebar">
            <Paper className="login-box" elevation={3}>
                <Typography variant="h4" className="login-title">Inscription</Typography>
                {error && <Typography color="error">{error}</Typography>}
                <form onSubmit={handleSubmit} className="login-form">
                    <div className="flex grid grid-cols-2 gap-4"><TextField
                        label="Prenom"
                        type="text"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={prenom}
                        onChange={(e) => setPrenom(e.target.value)}
                        required
                    />
                        <TextField
                            label="Nom"
                            type="text"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={nom}
                            onChange={(e) => setNom(e.target.value)}
                            required
                        />
                    </div>
                    <TextField
                        label="Email"
                        type="email"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <TextField
                        label="Mot de passe"
                        type="password"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <TextField
                        label="Confirmer le mot de passe"
                        type="password"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                    {/* <textarea
                        placeholder="Statut (Optionnel)"
                        label="Statut (Optionnel)"
                        type="text"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={statut}
                        onChange={(e) => setStatut(e.target.value)}
                        rows={3}
                        style={{ resize: "none", padding: "10px", borderRadius: "4px", border: "1px solid #ccc" }}
                    /> */}
                    {/* <TextField
                        label="Statut (Optionnel)"
                        type="text"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={statut}
                        onChange={(e) => setStatut(e.target.value)}
                        required
                    /> */}
                    <Button type="submit" variant="contained" color="primary" fullWidth>
                        S'inscrire
                    </Button>
                    {/* <p>Vous avez déjà un compte ? <Link to="/login">Se connecter</Link>.</p> */}
                    <p>
                    Vous avez déjà un compte ?
                        <Button
                            variant="text"
                            color="primary"
                            onClick={() => navigate('/login')}
                        >
                            SE CONNECTER
                        </Button>
                    </p>
                </form>
            </Paper>
        </div>
    );
};

export default Inscription;
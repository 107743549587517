import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from "react-router-dom";
import Login from "./pages/Login";
import Navbar from "./components/Navbar";
import Dashboard from "./pages/Dashboard";
import Vin from "./pages/Vin";
import NouveauVin from './pages/NouveauVin'; // Importer le composant NouveauVin
import Inscription from "./pages/Inscription";
import "./styles.css";

// Importer les styles de PrimeReact
import 'primereact/resources/themes/saga-blue/theme.css';  // Thème de PrimeReact
import 'primereact/resources/primereact.min.css';          // Styles de base de PrimeReact

function AppContent() {
  const isLoggedIn = !!sessionStorage.getItem('token');
  const location = useLocation(); // Obtenir le chemin actuel

  return (
    <>
      {/* Afficher la Navbar uniquement si le chemin n'est pas "/login" */}
      {location.pathname !== "/login" && location.pathname !== "/inscription" && <Navbar />}
      <Routes>

        <Route path="/login" element={isLoggedIn ? <Navigate to="/dashboard" replace /> : <Login />} />
        <Route path="/inscription" element={<Inscription />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/vin/:UUID_" element={<Vin />} />
        <Route path="/creation-vin" element={<NouveauVin />} />
        <Route path="/" element={<Dashboard />} />
        {/* ...other routes... */}
      </Routes>
    </>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
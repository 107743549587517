import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import authHeader from '../config/authHeader';
import config from '../config/config';

const useAuth = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [UUIDuser, setUUIDuser] = useState(null);

    const login = async () => {
        setError('');
        try {
            //debugger;
            const formData = new FormData();
            formData.append('email', email);
            formData.append('password', password);
            const response = await fetch(`${config.apiBaseUrl}/4DACTION/KST_AuthLogin`, { //fetch(`/4DACTION/KST_AuthLogin`, { //
                method: 'POST',
                headers: authHeader(),
                body: formData,
            });


            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const data = await response.json();

            if (response.ok && data.accessToken) {
                sessionStorage.setItem('token', data.accessToken);
                //console.log("data",data)
                setUUIDuser(data.uuidUser);
                sessionStorage.setItem('uuid_user', data.uuidUser);
                sessionStorage.setItem('nom_user', data.nomUser);

                //sessionStorage.setItem('isLoggedIn', 'true');
                navigate('/dashboard');

            } else {
                setError(data.response || 'Authentication failed');
            }
        } catch (err) {
            console.error("Failed to fetch:", err);
            setError('Failed to login. Please try again.');
        }
    };

    const logout = () => {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('uuid_user');
        sessionStorage.removeItem('nom_user');
        navigate('/login');
    };

    const isLoggedIn = () => {
        return !!sessionStorage.getItem('token');
        //return !!sessionStorage.getItem('isLoggedIn');
    };

    const getUserInfo = async () => {
        try {
            const token = sessionStorage.getItem('token');
            if (!token) return null;

            const response = await fetch(`${config.apiBaseUrl}/4DACTION/react_getUserInfo?UUID_=`, {
                method: 'GET',
                headers: authHeader(),
            });

            if (!response.ok) {
                throw new Error('Impossible de récupérer les infos utilisateur');
            }
            const userData = await response.json();
            return {
                Prenom: userData.Prenom,
                admin: userData.Admin,
                UUID: userData.UUID,
                Nom: userData.Nom,
                Email: userData.Email,
            };
        } catch (error) {
            console.error("Erreur lors de la récupération des infos utilisateur", error);
            return null;
        }
    };


    return {
        email,
        setEmail,
        password,
        setPassword,
        error,
        login,
        logout,
        isLoggedIn,
        getUserInfo,
        UUIDuser,
    };
};

export default useAuth;

export const handleTokenInvalidError = (error) => {
    if (error.message === 'token invalide') {
        sessionStorage.removeItem('token');
        window.location.reload();
    }
};
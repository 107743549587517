import React from "react";

import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";

export const SidebarData = [
  {
    section: "La cave",
    items: [
      {
        title: "Votre cave",
        path: "/",
        icon: <AiIcons.AiFillHome />,
        cName: "nav-text"
      },
      {
        title: "Répartition par pays",
        path: "/reports",
        icon: <IoIcons.IoIosPaper />,
        cName: "nav-text"
      },
      {
        title: "Dégustations",
        path: "/products",
        icon: <FaIcons.FaCartPlus />,
        cName: "nav-text"
      },
      {
        title: "Producteurs",
        path: "/team",
        icon: <IoIcons.IoMdPeople />,
        cName: "nav-text"
      }
    ]
  },
  {
    section: "Informations",
    items: [
      {
        title: "Mets et vins",
        path: "/",
        icon: <FaIcons.FaEnvelopeOpenText />,
        cName: "nav-text"
      },
      {
        title: "Bien gérer sa cave",
        path: "/support",
        icon: <IoIcons.IoMdHelpCircle />,
        cName: "nav-text"
      },
      {
        title: "Dégusation et service",
        path: "/support",
        icon: <IoIcons.IoMdHelpCircle />,
        cName: "nav-text"
      },
      {
        title: "Dictionnaire",
        path: "/support",
        icon: <IoIcons.IoMdHelpCircle />,
        cName: "nav-text"
      },
      {
        title: "Millésimes",
        path: "/support",
        icon: <IoIcons.IoMdHelpCircle />,
        cName: "nav-text"
      },
      {
        title: "Cépages",
        path: "/support",
        icon: <IoIcons.IoMdHelpCircle />,
        cName: "nav-text"
      }
    ]
  },
  {
    section: "Autres",
    items: [
      {
        title: "Favoris",
        path: "/support",
        icon: <FaIcons.FaHeart />,
        cName: "nav-text"
      }
    ]
  }
];

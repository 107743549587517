import React, { useState } from "react";
import { TextField, Button, Typography, Link, Paper } from "@mui/material";
import "../styles/Login.css"; // Import du CSS externe
import useAuth from '../hooks/useAuth';
import { useNavigate } from "react-router-dom";

const Login = () => {
    const {
        email,
        setEmail,
        password,
        setPassword,
        error,
        login,
    } = useAuth();
    const navigate = useNavigate(); // Hook pour la navigation
    const [localError, setLocalError] = useState(""); // Correction ici

    const handleSubmit = (e) => {
        e.preventDefault();
        login();
        if (!email || !password) {
            setLocalError("Veuillez remplir tous les champs !");
            return;
        }
    };

    return (
        <div className="login-container pageSidebar">
            <Paper className="login-box" elevation={3}>
                <Typography variant="h4" className="login-title">Connexion</Typography>
                {error && <Typography color="error">{error}</Typography>}
                <form onSubmit={handleSubmit} className="login-form">
                    <TextField
                        label="Email"
                        type="email"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <TextField
                        label="Mot de passe"
                        type="password"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <Button type="submit" variant="contained" color="primary" fullWidth>
                        Se connecter
                    </Button>
                    {/* <Link href="#" className="forgot-password">Mot de passe oublié ?</Link> */}
                    {/* <p>Vous n'avez pas de compte ? <Link to="/inscription">S'inscrire</Link>.</p> */}
                    <p>
                        Vous n'avez pas de compte ?
                        <Button
                            variant="text"
                            color="primary"
                            onClick={() => navigate('/inscription')}
                        >
                            S'inscrire
                        </Button>
                    </p>
                </form>
            </Paper>
        </div>
    );
};

export default Login;

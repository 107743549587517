import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import useFetchCaves from '../hooks/useFetchCaves';
import { Tag } from 'primereact/tag';
import { ProgressBar } from 'primereact/progressbar';
import { Rating } from 'primereact/rating';
import VinTabs from '../components/VinTabs';
import authHeader from '../config/authHeader';
import { useNavigate } from 'react-router-dom';
import { Stepper } from 'primereact/stepper';
import { StepperPanel } from 'primereact/stepperpanel';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Dropdown } from 'primereact/dropdown';
import '../styles/Vin.css'; // Importer le fichier CSS personnalisé
import { ProgressSpinner } from 'primereact/progressspinner';
import config from '../config/config';
import useAuth from '../hooks/useAuth';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Toast } from 'primereact/toast';

const NouveauVin = () => {
  const [vin, setVin] = useState(null);
  const [initialVin, setInitialVin] = useState(null);
  const [distinctCaves, setDistinctCaves] = useState([]); // État pour les valeurs distinctes des caves
  const [newCave, setNewCave] = useState("");
  const navigate = useNavigate();
  const stepperRef = useRef(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const UUIDuser = sessionStorage.getItem('uuid_user');
  const toast = useRef(null);
  //const [imageBase64, setImageBase64] = useState(vin.base64_etiquette);

  useEffect(() => {
    // Récupérer le modèle de vin vide depuis l'API
    fetch(`${config.apiBaseUrl}/4DACTION/react_createCave`)
      .then(response => response.json())
      .then(data => {
        setVin(data);
        setInitialVin(data);
      })
      .catch(error => console.error('Erreur lors de la récupération du modèle:', error))
    // Charger les valeurs distinctes des caves
    const storedCaves = JSON.parse(localStorage.getItem("distinctCaves")) || [];
    setDistinctCaves(storedCaves);;
  }, []);

  const handleAddNewCave = () => {
    if (newCave.trim() !== "" && !distinctCaves.includes(newCave)) {
      const updatedCaves = [...distinctCaves, newCave];
      setDistinctCaves(updatedCaves);
      localStorage.setItem("distinctCaves", JSON.stringify(updatedCaves)); // Stocker dans le localStorage
      setNewCave(""); // Réinitialiser le champ
    }
  };

  const handleCaveChange = (value) => {
    setVin((prevVin) => ({
      ...prevVin,
      Cave: value, // Mettre à jour la cave sélectionnée
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVin((prevVin) => ({
      ...prevVin,
      [name]: value,
    }));
  };
  const getModifiedFields = () => {
    const modifiedFields = {};
    Object.keys(vin).forEach((key) => {
      if (vin[key] !== initialVin[key]) {
        modifiedFields[key] = vin[key];  // Ajouter uniquement les champs modifiés
      }
    });

    return modifiedFields;
  };
  const handleSave = async () => {
    try {
      //const uuid = vin.UUID_;
      //const modifiedVinJson = JSON.stringify(vin);
      const modifiedVin = getModifiedFields();  // Obtenir uniquement les champs modifiés

      const modifiedVinJson = JSON.stringify(modifiedVin);
      const formData = new FormData();
      //formData.append("UUID_", uuid);
      formData.append("champsModif", modifiedVinJson);
      formData.append("action", "creation");
      formData.append("UUIDuser", UUIDuser);
      const response = await fetch(`${config.apiBaseUrl}/4DACTION/react_putCave`, {
        //const response = await fetch(`/4DACTION/react_putCave`, {
        method: 'PUT',  // ou 'PATCH' selon l'API
        headers: authHeader(),
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la sauvegarde');
      }

      const updatedCave = await response.json();
      toast.current.show({severity:'success', summary: 'Success', detail:'Modifications enregistrées avec succès', life: 3000});
      //alert('Modifications enregistrées avec succès');
      //debugger;
      setVin(updatedCave);
      setInitialVin(updatedCave);
      //fetchCave(updatedCave.UUID_);  // Récupérer les dernières données du serveur
      //fetchCaves();
      navigate(`/vin/${updatedCave.UUID_}`);
    } catch (error) {
      toast.current.show({severity:'warn', summary: 'Warning', detail:'Erreur lors de sauvegarde', life: 3000});
      //alert('Erreur de sauvegarde: ' + error.message);
    }
  };

  const customBase64Uploader = (event) => {
    //debugger;
    const file = event.files[0];

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);  // Convertit en Base64
      reader.onload = () => {
        //vin.base64_etiquette = reader.result;  // Met à jour le vin avec l'image
        const base64String = reader.result.split(',')[1]; // Enlève le préfixe
        //setImageBase64(base64String);  // Met à jour l'image
        setVin((prevVin) => ({
          ...prevVin,
          base64_etiquette: base64String, // Met à jour directement l’objet `vin`
        }));
      };
      AnalyseIA();
    }
  };

  const traiterAnalyseIA = (jsonAtraite) => {
    // Liste des clés à ne PAS inclure dans Remarque
    const keysExclues = [
      "nom", "region", "pays", "appellation", "millesime", "type_de_vin",
      "APOGEE", "taux_d_alcool", "prix_moyen", "cepages"
    ];

    // Génération dynamique de Remarque avec les autres champs
    const remarques = Object.entries(jsonAtraite)
      .filter(([key]) => !keysExclues.includes(key)) // Exclure les champs déjà utilisés
      .map(([key, value]) => `${key.replace(/_/g, " ")} : ${value}`) // Formater en texte lisible
      .join(", "); // Joindre en une seule chaîne

    //console.log("Remarques générées :", remarques);
    /*
    setVin((prevVin) => ({
      ...prevVin,
      Nom: jsonAtraite.nom ?? "", // Si undefined → ""
      Région: jsonAtraite.région ?? "",
      Pays: jsonAtraite.pays ?? "",
      Appellation: jsonAtraite.appellation ?? "",
      Millesime: jsonAtraite.millesime ?? "",
      Type: jsonAtraite.type_de_vin ?? "",
      Apogee: jsonAtraite.APOGEE ? jsonAtraite.APOGEE.split("-")[0] : "", // Vérifie que APOGEE existe
      Apogee_Max: jsonAtraite.APOGEE ? jsonAtraite.APOGEE.split("-")[1] : "",
      Alcool: jsonAtraite.taux_d_alcool ? parseFloat(jsonAtraite.taux_d_alcool) : 0, // Convertir en nombre ou 0 si vide
      Prix_Achat: jsonAtraite.prix_moyen ? parseFloat(jsonAtraite.prix_moyen.replace(/[^0-9.]/g, "")) : 0, // Extraire nombre ou 0
      Cepage: Array.isArray(jsonAtraite.cepages) ? jsonAtraite.cepages.join(", ") : "", // Vérifie si c'est bien un tableau
      Remarques: remarques, // Ajout des autres champs dynamiquement


    }));*/
    setVin((prevVin) => ({
      ...prevVin,
      Nom: jsonAtraite.nom ?? "", // Si undefined → ""
      Région: jsonAtraite.région ?? "",
      Pays: jsonAtraite.pays ?? "",
      Appellation: jsonAtraite.appellation ?? "",
      Millesime: jsonAtraite.millesime ?? "",
      Type: jsonAtraite.type_de_vin ?? "",
      Apogee: jsonAtraite.APOGEE ? jsonAtraite.APOGEE.split("-")[0] : "", // Vérifie que APOGEE existe
      Apogee_Max: jsonAtraite.APOGEE ? jsonAtraite.APOGEE.split("-")[1] : "",
      Alcool: jsonAtraite.taux_d_alcool ? parseFloat(jsonAtraite.taux_d_alcool) : 0, // Convertir en nombre ou 0 si vide
      Prix_Achat: typeof jsonAtraite.prix_moyen === "string"
        ? parseFloat(jsonAtraite.prix_moyen.replace(/[^0-9.]/g, ""))
        : (typeof jsonAtraite.prix_moyen === "number" ? jsonAtraite.prix_moyen : 0), // Gérer les cas où c'est un nombre ou autre
      Cepage: Array.isArray(jsonAtraite.cepages) ? jsonAtraite.cepages.join(", ") : "", // Vérifie si c'est bien un tableau
      Remarques: remarques, // Ajout des autres champs dynamiquement
    }));
  };

  const AnalyseIA = async (retry = false) => {
    try {
      setIsAnalyzing(true); // Démarrer le chargement
      const formData = new FormData();
      formData.append("b64", vin.base64_etiquette);
      const response = await fetch(`${config.apiBaseUrl}/4DACTION/react_analyseIA`, {
        method: 'POST', // ou 'PATCH' selon l'API
        headers: authHeader(),
        body: formData,
      });
      if (!response.ok) {
        throw new Error("Erreur lors de l'analyse");
      }
      const jsonAtraite = await response.json();

      // Vérifier si la réponse est undefined ou invalide
      if (!jsonAtraite || Object.keys(jsonAtraite).length === 0) {
        if (!retry) {
          toast.current.show({severity:'Secondary', summary: 'Secondary', detail:'Réponse vide ou undefined, nouvelle tentative...', life: 3000});
          //toast.warn('Réponse vide ou undefined, nouvelle tentative...', { autoClose: 3000 });
          //console.warn('Réponse vide ou undefined, nouvelle tentative...');
          await AnalyseIA(true); // Relancer une deuxième tentative
          return; // Arrêter l'exécution de la tentative actuelle
        } else {
          throw new Error("Réponse vide après deux tentatives");
        }
      }
      traiterAnalyseIA(jsonAtraite);
      // Passer à l'étape suivante du Stepper
      if (stepperRef.current && stepperRef.current.nextCallback) {
        toast.current.show({severity:'success', summary: 'Success', detail:'Analyse réussite', life: 3000});
        stepperRef.current.nextCallback();
      }
    } catch (error) {
      if (!retry) {
        toast.current.show({severity:'Secondary', summary: 'Secondary', detail:'Première tentative échouée, nouvelle tentative...', life: 3000});
        //toast.warn('Première tentative échouée, nouvelle tentative...', { autoClose: 3000 });
        // Relancer une deuxième tentative
        await AnalyseIA(true);
      } else {
        toast.current.show({severity:'warn', summary: 'Warning', detail:"Erreur lors de l'analyse après deux tentatives", life: 3000});
        //toast.warn("Erreur lors de l'analyse après deux tentatives", { autoClose: 3000 })
        //alert('Erreur lors de l\'analyse après deux tentatives : ' + error.message);
      }
    } finally {
      setIsAnalyzing(false); // Arrêter le chargement
    }
  };

  var bas64Vide = ""
  if (!vin) return <p>Chargement du formulaire...</p>;
  const handleFileUpload = (event) => {
    console.log("Fichiers uploadés :", event.files);
  };
  return (
    <div className="flex flex-col items-center min-h-screen bg-gray-100">
      <Toast ref={toast} />
      {vin ? (
        <>
          <div className="flex flex-col items-center gap-4 p-4 rounded-2xl shadow-lg bg-white w-full max-w-6xl align-items-center">
            <Stepper ref={stepperRef} style={{ flexBasis: '50rem' }}>
              <StepperPanel header="Choix image">
                {isAnalyzing ? (
                  // Afficher le ProgressSpinner si isAnalyzing est actif
                  <div className="flex items-center justify-center w-full h-full">
                    <ProgressSpinner
                      style={{ width: '50px', height: '50px' }}
                      strokeWidth="8"
                      fill="var(--surface-ground)"
                      animationDuration=".5s"
                    />
                  </div>
                ) : (
                  // Afficher le contenu par défaut si isAnalyzing est inactif
                  <div className="flex grid gap-4 flex-shrink-0 gap-2 items-center justify-center">
                    <div className="card flex flex-column items-center justify-center">
                      <FileUpload
                        name="demo[]"
                        mode="advanced"
                        chooseLabel="Importer une image"
                        uploadLabel="Télécharger"
                        cancelLabel="Annuler"
                        accept="image/*"
                        auto={true}
                        maxFileSize={1000000}
                        customUpload={true}
                        onSelect={customBase64Uploader} // Récupère le fichier
                        style={{ alignItems: 'center', justifyContent: 'center' }}
                        emptyTemplate={<p className="m-0">Glisser ou importer un fichier.</p>}
                      />
                    </div>
                    <button
                      className="p-2 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 transition duration-300"
                      onClick={() => stepperRef.current.nextCallback()}
                    >
                      Remplir manuellement
                    </button>
                  </div>
                )}
              </StepperPanel>
              <StepperPanel header="Votre vin">
                <div className="flex items-center justify-center w-full h-full">
                  <img
                    src={`data:image/jpeg;base64,${vin.base64_etiquette}`}
                    alt="Cave Image"
                    className="w-48 h-48 object-contain shadow-xl rounded-md"
                  />
                </div>
                <div className="fex flex-row w-full">
                  <div className="mb-4">
                    <label className="block text-gray-700 font-bold text-sm md:text-base">Nom:</label>
                    <input
                      type="text"
                      name="Nom"
                      value={vin.Nom}
                      onChange={handleInputChange}
                      className="w-full p-1 md:p-2 border border-gray-300 rounded-md bg-gray-100 text-sm md:text-base"
                    />
                  </div>
                  <div className="flex w-full grid grid-cols-2 sm:grid-cols-2 gap-4 ">

                    {/* Dropdown pour sélectionner une cave */}
                    <div>
                      <label className="text-gray-700 font-bold text-sm md:text-base">Cave:</label>
                      <Dropdown
                        value={vin?.Cave || ""}
                        options={distinctCaves}
                        onChange={(e) => handleCaveChange(e.value)}
                        placeholder="Cave"
                        className="w-full p-1 md:p-2 border border-gray-300 rounded-md bg-gray-100 text-sm "
                        showClear
                      />
                    </div>

                    <div >
                      <label className="block text-gray-700 font-bold text-sm md:text-base">Appellation:</label>
                      <input
                        type="text"
                        name="Appellation"
                        value={vin.Appellation}
                        onChange={handleInputChange}
                        className="w-full p-1 md:p-2 border border-gray-300 rounded-md bg-gray-100 text-sm md:text-base"
                      />
                    </div>
                    <div >
                      <label className="block text-gray-700 font-bold text-sm md:text-base">Type:</label>
                      <input
                        type="text"
                        name="Type"
                        value={vin.Type}
                        onChange={handleInputChange}
                        className="w-full p-1 md:p-2 border border-gray-300 rounded-md bg-gray-100 text-sm md:text-base"
                      />
                    </div>
                    <div >
                      <label className="block text-gray-700 font-bold text-sm md:text-base">Pays:</label>
                      <input
                        type="text"
                        name="Pays"
                        value={vin.Pays}
                        onChange={handleInputChange}
                        className="w-full p-1 md:p-2 border border-gray-300 rounded-md bg-gray-100 text-sm md:text-base"
                      />
                    </div>
                    <div>
                      <label className="block text-gray-700 font-bold text-sm md:text-base">Région:</label>
                      <input
                        type="text"
                        name="Région"
                        value={vin.Région}
                        onChange={handleInputChange}
                        className="w-full p-1 md:p-2 border border-gray-300 rounded-md bg-gray-100 text-sm md:text-base"
                      />
                    </div>
                    <div>
                      <label className="block text-gray-700 font-bold text-sm md:text-base">Millésime:</label>
                      <input
                        type="text"
                        name="Millesime"
                        value={vin.Millesime}
                        onChange={handleInputChange}
                        className="w-full p-1 md:p-2 border border-gray-300 rounded-md bg-gray-100 text-sm md:text-base"
                      />
                    </div>
                    <div>
                      <label className="block text-gray-700 font-bold text-sm md:text-base">Valeur:</label>
                      <input
                        type="text"
                        name="Valeur_Euro"
                        value={vin.Valeur_Euro ? vin.Valeur_Euro.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' }) : 'N/A'}
                        onChange={handleInputChange}
                        className="w-full p-1 md:p-2 border border-gray-300 rounded-md bg-gray-100 text-sm md:text-base"
                      />
                    </div>
                    <div>
                      <label className="block text-gray-700 font-bold text-sm md:text-base">Couleur:</label>
                      <input
                        type="text"
                        name="Couleur"
                        value={vin.Couleur}
                        onChange={handleInputChange}
                        className="w-full p-1 md:p-2 border border-gray-300 rounded-md bg-gray-100 text-sm md:text-base"
                      />
                    </div>
                    <div>
                      <label className="block text-gray-700 font-bold text-sm md:text-base">Alcool:</label>
                      <input
                        type="text"
                        name="Alcool"
                        value={vin.Alcool}
                        onChange={handleInputChange}
                        className="w-full p-1 md:p-2 border border-gray-300 rounded-md bg-gray-100 text-sm md:text-base"
                      />
                    </div>
                    <div>
                      <label className="block text-gray-700 font-bold text-sm md:text-base">Cépage:</label>
                      <input
                        type="text"
                        name="Cepage"
                        value={vin.Cepage}
                        onChange={handleInputChange}

                        className="w-full p-1 md:p-2 border border-gray-300 rounded-md bg-gray-100 text-sm md:text-base"
                      />
                    </div>
                    <div>
                      <label className="block text-gray-700 font-bold text-sm md:text-base">Note:</label>
                      <Rating value={vin.Note_sur_20 > 5 ? vin.Note_sur_20 / 5 : vin.Note_sur_20}//value={vin.Note_sur_20 / 5}
                        name="Note_sur_20"
                        onChange={handleInputChange}
                        cancel={false} />
                    </div>
                    <div>
                      <label className="block text-gray-700 font-bold text-sm md:text-base">Reste en Cave:</label>
                      <ProgressBar value={vin.Reste_en_Cave} showValue={true} name="Reste_en_Cave" />
                    </div>
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 font-bold text-sm md:text-base">Remarques:</label>
                    <textarea
                      type="text"
                      name="Remarques"
                      value={vin.Remarques}
                      onChange={handleInputChange}
                      className="w-full p-1 md:p-2 border border-gray-300 rounded-md bg-gray-100 text-sm md:text-base"
                    />
                  </div>
                </div>
              </StepperPanel>
            </Stepper>
          </div>
          <div className="mt-4 bg-gray-100 p-4 w-full max-w-6xl flex justify-center">
            <button
              onClick={handleSave}
              className="ml-4 p-2 bg-green-500 text-black rounded-md"
            >
              Sauvegarder
            </button>
          </div>

          <div className="mt-4 w-full max-w-6xl">
            <VinTabs vin={vin} handleInputChange={handleInputChange} />
          </div>


        </>
      ) : (
        <div className="p-4 text-center text-gray-500">Vin non trouvé</div>
      )
      }
    </div >
  );
};

export default NouveauVin;

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { IconField } from 'primereact/iconfield';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { ProgressBar } from 'primereact/progressbar';
import { FilterMatchMode } from 'primereact/api';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Panel } from 'primereact/panel';

export default function LstCave({ listeCaves }) {
    const navigate = useNavigate();
    // Initialiser l'état caves avec listeCaves directement
    const [caves, setCaves] = useState(listeCaves);
    const [selectedCaves, setSelectedCaves] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Région: { value: null, matchMode: FilterMatchMode.EQUALS },
        Type: { value: null, matchMode: FilterMatchMode.EQUALS },
        Cave: { value: null, matchMode: FilterMatchMode.EQUALS },
        Millesime: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    const [expandedRows, setExpandedRows] = useState([]);
    const [regions] = useState(listeCaves.map(cave => cave.Région).filter((value, index, self) => self.indexOf(value) === index));
    const [pays] = useState(listeCaves.map(cave => cave.Pays).filter((value, index, self) => self.indexOf(value) === index));
    const [types] = useState(listeCaves.map(cave => cave.Type).filter((value, index, self) => self.indexOf(value) === index));
    const [millesime] = useState(listeCaves.map(cave => cave.Millesime).filter((value, index, self) => self.indexOf(value) === index));
    const formatCurrency = (value) => {
        return value.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' });
    };
    const totalPrice = listeCaves.reduce((sum, row) => sum + row.Valeur_Euro, 0);
    const totalReste = listeCaves.reduce((sum, row) => sum + row.Reste_en_Cave, 0);

    const [selectedCave, setSelectedCave] = useState(null); // État pour la valeur sélectionnée
    const [distinctCaves, setDistinctCaves] = useState([]); // État pour les valeurs distinctes de cave.Cave
    const [newCave, setNewCave] = useState(''); // Nouvelle valeur à ajouter

    // Charger les options depuis le localStorage ou initialiser avec les valeurs distinctes
    useEffect(() => {
        const storedCaves = JSON.parse(localStorage.getItem('distinctCaves')) || [];
        if (storedCaves.length > 0) {
            setDistinctCaves(storedCaves);
        } else {
            const uniqueCaves = [...new Set(listeCaves.map((cave) => cave.Cave))];
            setDistinctCaves(uniqueCaves);
            localStorage.setItem('distinctCaves', JSON.stringify(uniqueCaves));
        }
    }, [listeCaves]);
    // Fonction pour gérer le changement de valeur dans le Dropdown
    const handleCaveChange = (value) => {
        setSelectedCave(value);
        // Filtrer les caves en fonction de la sélection
        if (value) {
            const filteredCaves = listeCaves.filter((cave) => cave.Cave === value);
            setCaves(filteredCaves); // Met à jour les données affichées dans le tableau
        } else {
            setCaves(listeCaves); // Réinitialise les données si aucune valeur n'est sélectionnée
        }
    };
    const handleAddNewCave = () => {
        if (newCave.trim() !== '' && !distinctCaves.includes(newCave)) {
            const updatedCaves = [...distinctCaves, newCave];
            setDistinctCaves(updatedCaves);
            localStorage.setItem('distinctCaves', JSON.stringify(updatedCaves));
            setNewCave(''); // Réinitialiser le champ de saisie
        }
    };

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    useEffect(() => {
        setCaves(listeCaves);
    }, [listeCaves]);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        // Définir `isMobile` au montage du composant
        handleResize();
        // Ajouter l'écouteur d'événement
        window.addEventListener("resize", handleResize);
        // Nettoyage lors du démontage du composant
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []); // Déclenché une seule fois au montage

    const exportCSV = () => {
        dt.current.exportCSV();
    };
    const ajouterVin = () => {
        navigate('/creation-vin'); // Rediriger vers la page NouveauVin
    };

    const rightToolbarTemplate = () => {
        return <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />;
    };

    const headerTemplate = (data) => {
        const imageBase64 = data.base64_etiquette;
        return (
            <div className="flex items-center gap-4 p-2 cursor-pointer" onClick={() => onRowDoubleClick({ data })}>
                <img
                    alt="Cave Image"
                    src={`data:image/jpeg;base64,${imageBase64}`}
                    className="w-20 h-auto rounded-md object-cover"
                />
                <div className="flex flex-col text-sm">
                    <span className="font-bold text-base sm:text-lg">{data.Nom}</span>
                    <p className="text-gray-600">Millésime : {data.Millesime}</p>
                    <p className="text-gray-600">Type : {data.Type}</p>
                    <p className="text-gray-600">Région : {data.Région}</p>
                </div>
            </div>
        );
    };


    const paysBodyTemplate = (rowData) => {
        return <Tag value={rowData.Pays} severity={rowData.Pays}></Tag>;
    };

    const header = (
        <div className="flex gap-2 align-center justify-between p-0 text-sm sm:text-lg !important">
            <IconField iconPosition="left" className="flex justify-content-between !important">
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)}
                    className="w-full p-1 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300"
                    placeholder="Recherche vin..." />
            </IconField>

            <button
                onClick={ajouterVin}
                className="p-2 bg-red-500 bg-red-important text-white font-semibold rounded-lg shadow-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-75 transition duration-300"
                style={{ backgroundColor: 'red !important' }}
            >
                Ajouter un Vin
            </button>
        </div>
    );
    const onRowDoubleClick = (e) => {
        console.log(e.data);
        console.log(e.data.UUID_);
        navigate(`/vin/${e.data.UUID_}`);
    };
    const typesFilterTemplate = (options) => {
        return (
            <Dropdown value={options.value} options={types} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={typesItemTemplate} placeholder="Choisir" className="p-column-filter" showClear style={{ minWidth: '12rem', color: 'black !important' }} />
        );
    };
    const typesItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option}</span>
            </div>
        );
    };
    const regionsFilterTemplate = (options) => {
        return (
            <Dropdown value={options.value} options={regions} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={typesItemTemplate} placeholder="Choisir" className="p-column-filter" showClear style={{ minWidth: '12rem' }} />
        );
    };
    const millesimesFilterTemplate = (options) => {
        return (
            <Dropdown value={options.value} options={millesime} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={typesItemTemplate} placeholder="Choisir" className="p-column-filter" showClear style={{ minWidth: '12rem' }} />
        );
    };

    const paysRowFilterTemplate = (options) => {
        return (
            <MultiSelect
                value={options.value}
                options={pays}
                //itemTemplate={paysItemTemplate}
                itemTemplate={paysBodyTemplate}
                onChange={(e) => options.filterApplyCallback(e.value)}
                optionLabel="name"
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    };
    const imageBodyTemplate = (rowData) => {
        // Vérifier si rowData.Image contient une chaîne base64
        const imageBase64 = rowData.base64_etiquette;  // La chaîne base64 représentant l'image
        return (
            <img
                src={`data:image/jpeg;base64,${imageBase64}`}  // Prefixer avec le type MIME de l'image (JPEG ici, ajustez si nécessaire)
                alt="Cave Image"
                className="shadow-2 border-round"
                style={{
                    width: '64px',    // Largeur fixe
                    height: '64px',   // Hauteur fixe
                    objectFit: 'contain' // Pour s'assurer que l'image garde ses proportions sans déformation
                }}
            />
        );
    };
    const priceBodyTemplate = (rowData) => {
        return formatCurrency(rowData.Valeur_Euro);
    };
    const ratingBodyTemplate = (rowData) => {
        return <Rating value={rowData.Note_sur_20 / 5} readOnly cancel={false} />;
    };
    const resteCaveBodyTemplate = (rowData) => {
        return <ProgressBar value={rowData.Reste_en_Cave} showValue={false} style={{ height: '6px' }}></ProgressBar>;
    };
    const headerPanel = (options) => {return (<div >Filtrer</div>);};

    return (
        <div>
            {isMobile ? (
                <div className="card">
                    <div className="flex ">
                        <Panel toggleable collapsed={true} className='max-w-full p-0' pt={{ content: { className: "p-1 px-2 !importante" },header: { className: "p-1" }, }}> {/*headerTemplate={headerPanel} */}
                            <div className="flex text-sm p-0 !important gap-1">
                                <Dropdown value={selectedCave} options={distinctCaves}
                                    onChange={(e) => handleCaveChange(e.value)}
                                    placeholder="Filtrer Cave" className="w-30 text-sm"
                                    pt={{label: { className: "text-sm" },input: { className: "text-sm" },panel: { className: "text-sm" }, item: { className: "text-sm" }, }}
                                    style={{fontSize: "0.875rem !important", width: "10rem", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", }}
                                    showClear/>
                                <div className="flex gap-1 mt-2">
                                    <InputText value={newCave} onChange={(e) => setNewCave(e.target.value)}
                                        placeholder="Ajouter une nouvelle Cave"
                                        className="w-40 border border-gray-300 rounded-md shadow-sm text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300"/>
                                    <button onClick={handleAddNewCave}
                                        className="p-1 bg-blue-500 text-white text-sm font-medium rounded-md hover:bg-blue-600 transition duration-300">
                                        Ajouter
                                    </button>
                                </div>

                            </div>
                        </Panel>
                    </div>

                    <DataTable ref={dt} value={caves} rowGroupMode="subheader" groupRowsBy="Nom"
                        sortMode="multiple" sortField="Nom" sortOrder={1}
                        expandableRowGroups expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                        rowGroupHeaderTemplate={headerTemplate} footer={`Nombre de vin différents: ${caves.length}`} tableStyle={{ maxWidth: '100% !important' }}
                        scrollable scrollHeight="calc(100vh - 200px)" header={header}
                        globalFilterFields={['Nom', 'Pays', 'Région', 'Type', 'Cave']} globalFilter={globalFilter} filters={filters}
                        showGridlines onRowDoubleClick={onRowDoubleClick} emptyMessage="Aucun vin enregistré." onRowClick={onRowDoubleClick}>
                        <Column field="Type" header="Type"
                            style={{ width: '20%' }} headerClassName="text-xs font-semibold text-gray-600"
                            sortable filter filterElement={typesFilterTemplate} showFilterMatchModes={false} ></Column>
                        <Column field="Région" header="Région"
                            style={{ width: '20%' }} headerClassName="text-xs font-semibold text-gray-600"
                            sortable filter filterElement={regionsFilterTemplate} showFilterMatchModes={false}></Column>
                        <Column field="Millesime" header="Millesime"
                            style={{ width: '20%' }} headerClassName="text-xs font-semibold text-gray-600"
                            sortable filter filterElement={millesimesFilterTemplate} showFilterMatchModes={false}></Column>
                    </DataTable>
                    {/* <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar> */}
                </div>

            ) : (

                <div className="card" style={{ padding: '1rem' }}>
                    <div className="flex flex-wrap gap-4 items-center justify-center w-full md:w-auto">
                        <Dropdown value={selectedCave} options={distinctCaves}
                            onChange={(e) => handleCaveChange(e.value)} placeholder="Filtrer par Cave"
                            className="p-column-filter" showClear style={{ minWidth: '12rem' }} />
                        <div className="flex flex-wrap sm:flex-row gap-2 items-center w-full md:w-auto space-y-2">
                            <InputText value={newCave}
                                onChange={(e) => setNewCave(e.target.value)}
                                placeholder="Ajouter une nouvelle Cave"
                                className="w-full md:w-auto border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300"
                                style={{ minWidth: '12rem' }} />
                            <button onClick={handleAddNewCave}
                                className="p-2 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 transition duration-300">
                                Ajouter
                            </button>
                        </div>
                    </div>

                    <DataTable ref={dt} value={caves} onSelectionChange={(e) => setSelectedCaves(e.value)} //selection={selectedCaves}  showGridlines
                        dataKey="UUID_" filters={filters} globalFilterFields={['Nom', 'Pays', 'Région', 'Type', 'Cave']}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} caves" globalFilter={globalFilter} header={header}
                        scrollable scrollHeight="calc(100vh - 300px)" onRowDoubleClick={onRowDoubleClick} emptyMessage="Aucun vin enregistré.">
                        <Column field="Nom" header="Nom" footer={`Nombre de vin différents: ${caves.length}`} sortable style={{ minWidth: '18rem' }}></Column>
                        <Column field="Type" header="Type" sortable style={{ minWidth: '8rem' }} filter filterElement={typesFilterTemplate} showFilterMatchModes={false}></Column>
                        <Column field="image" header="Image" body={imageBodyTemplate}></Column>
                        <Column field="Pays" header="Pays" sortable style={{ minWidth: '12rem' }} body={paysBodyTemplate} showFilterMenu={false} filterMenuStyle={{ width: '10rem' }} filter filterElement={paysRowFilterTemplate}></Column>
                        <Column field="Région" header="Région" sortable style={{ minWidth: '11rem' }} filter filterElement={regionsFilterTemplate} showFilterMatchModes={false}></Column>
                        <Column field="Reste_en_Cave" header="Reste_en_Cave" footer={`Reste: ${totalReste}`} body={resteCaveBodyTemplate} sortable style={{ minWidth: '8rem' }}></Column>
                        <Column field="Millesime" header="Millésime" sortable style={{ minWidth: '7rem', textAlign: 'center' }} filter filterElement={millesimesFilterTemplate} showFilterMatchModes={false}></Column>
                        <Column field="Valeur_Euro" header="Prix" footer={totalPrice.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })} body={priceBodyTemplate} sortable style={{ minWidth: '8rem', textAlign: 'right' }}></Column>
                        <Column field="Note_sur_20" header="Note" body={ratingBodyTemplate} sortable style={{ minWidth: '12rem' }}></Column>
                    </DataTable>
                    <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>
                </div>
            )}
        </div>
    );
}
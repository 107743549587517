import React from 'react';
import { Box, Typography, Avatar, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as AiIcons from "react-icons/ai";
import "../styles/Navbar.css";
import useAuth from "../hooks/useAuth";


const StyledCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: '20px',
  background: 'rgba(255, 255, 255, 0.3)',
  backdropFilter: 'blur(10px)',
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
  textAlign: 'center',
  minWidth: '250px',
  maxWidth: '300px',
}));

const StyledAvatar = styled(Avatar)({
  width: 80,
  height: 80,
  margin: '0 auto 16px auto',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
});

const Card = ({ nomComplet }) => {
  const { logout } = useAuth();
  return (
    <StyledCard elevation={0}>
      <StyledAvatar src="/images/default-avatar.jpg" alt="Profile" />
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        {nomComplet}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Amateur de vin
      </Typography>
      <button onClick={logout} style={{  background: 'black', border: 'none', cursor: 'pointer', color: 'white', padding: '10px 20px', borderRadius: '5px', marginTop: '20px',  alignItems: 'center', justifyContent: 'center', width: '100%', display: 'flex', fontSize: '16px' }}>
        <AiIcons.AiOutlineLogout />  Déconnexion
      </button>
    </StyledCard>

  );
};

export default Card;

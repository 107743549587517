import React, { useEffect } from "react";
import LstCave from "../components/Lst_Cave";
import useFetchCaves from '../hooks/useFetchCaves';
import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const Dashboard = () => {
  const { caves, error, loading, fetchCaves } = useFetchCaves();
  useEffect(() => {
    fetchCaves();
  }, [fetchCaves]);

  if (!sessionStorage.getItem('token')) {
    return <Navigate to="/login" replace />;
  }

  if (loading) {
    return <div>Chargement...</div>;
  }

  if (error) {
    return <div>Erreur: {error}</div>;
  }

  return (
    <div className="pageSidebar">
      <LstCave listeCaves={caves} />
    </div>
  );
}

export default Dashboard;
import React, { useState } from 'react';
import { Slider } from 'primereact/slider';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const WallPaper = styled('div')({
  borderRadius: 16,
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  overflow: 'hidden',
  background: 'linear-gradient(45deg, #e4dd16, #a7b506, #ffffff, #cd3a0f)',
  transition: 'all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s',
  '&::before': {
    content: '""',
    width: '140%',
    height: '140%',
    position: 'absolute',
    top: '-40%',
    right: '-50%',
    background: 'radial-gradient(at center center, rgb(211, 249, 62) 0%, rgba(249, 104, 26, 0) 64%)',
  },
  '&::after': {
    content: '""',
    width: '140%',
    height: '140%',
    position: 'absolute',
    bottom: '-50%',
    left: '-30%',
    background: 'radial-gradient(at center center, rgb(247, 237, 225) 0%, rgba(247, 237, 225, 0) 70%)',
    transform: 'rotate(30deg)',
  },
});

const Widget = styled('div')({
  marginTop: 10,
  padding: 20,
  borderRadius: 16,
  width: 400,
  maxWidth: '95%',
  position: 'relative',
  zIndex: 1,
  backgroundColor: 'rgba(255,255,255,0.4)',
  backdropFilter: 'blur(40px)',
});

const HeaderImage = styled('img')({
  width: '60px',
  height: '60px',
  objectFit: 'contain',
  borderRadius: '12px',
  flexShrink: 0,
});

const VinDegustation = ({ vin }) => {
  const img_oeil = '/images/oeil-vert.png';
  const img_palais = '/images/palais.png';
  const limpiditeLabels = ['Limpide', 'Terne', 'Trouble'];
  const intensiteLabels = ['Pâle', 'Moyenne', 'Intense'];
  const couleurLabels = {
    Blanc: ['Jaune-vert', 'Jaune-Citron', 'Ambré', 'Brun'],
    Rosé: ['Rose', 'Saumon', 'Orangé'],
    Rouge: ['Violé', 'Rubis', 'Grenat', 'Tuilé', 'Brun'],
  };

  const typeCouleur = vin.Couleur || 'Rouge';
  const couleurOptions = couleurLabels[typeCouleur] || [];

  const [limpidite, setLimpidite] = useState(0);
  const [intensite, setIntensite] = useState(0);
  const [couleur, setCouleur] = useState(0);

  const getLabel = (arr, index) => arr[Math.round(index / (100 / (arr.length - 1)))];

  const handleClickStep = (setter, index, arr) => {
    const value = (100 / (arr.length - 1)) * index;
    setter(value);
  };

  const renderSteps = (arr, setter) => (
    <div className="flex justify-between mt-2">
      {arr.map((label, idx) => (
        <span
          key={idx}
          className="cursor-pointer text-sm px-2 py-1 rounded-md bg-gray-100 hover:bg-gray-200 transition-all duration-200 ease-in-out"
          onClick={() => handleClickStep(setter, idx, arr)}
        >
          {label}
        </span>
      ))}
    </div>
  );

  return (
    <Box sx={{ position: 'relative', p: 2, borderRadius: 16 }}>
      <div className=" ">
         <div className="transition-all duration-500 "> {/* hover:scale-[1.01] hover:shadow-lg */}
          <Widget className="animate-slide-in-left">
            <Box className="flex items-center gap-4 mb-4 bg-white rounded-md p-2">
              <HeaderImage src={img_oeil} alt="Logo" />
              <Typography variant="h5" gutterBottom sx={{ mb: 0 }}>
                Analyse Visuelle
              </Typography>
            </Box>

            <div className="field mb-4">
              <label className="block mb-2">
                Limpidité : <strong>{getLabel(limpiditeLabels, limpidite)}</strong>
              </label>
              <Slider value={limpidite} min={0} max={100} step={50} onChange={(e) => setLimpidite(e.value)} />
              {renderSteps(limpiditeLabels, setLimpidite)}
            </div>

            <div className="field mb-4">
              <label className="block mb-2">
                Intensité : <strong>{getLabel(intensiteLabels, intensite)}</strong>
              </label>
              <Slider value={intensite} min={0} max={100} step={50} onChange={(e) => setIntensite(e.value)} />
              {renderSteps(intensiteLabels, setIntensite)}
            </div>

            <div className="field mb-4">
              <label className="block mb-2">
                Couleur ({typeCouleur}) : <strong>{getLabel(couleurOptions, couleur)}</strong>
              </label>
              <Slider
                value={couleur}
                min={0}
                max={100}
                step={100 / (couleurOptions.length - 1)}
                onChange={(e) => setCouleur(e.value)}
              />
              {renderSteps(couleurOptions, setCouleur)}
            </div>
          </Widget>
        </div>

        <div className="transition-all duration-500 delay-200 animate-fade-in-up">
          <Widget className="mt-6">
            <Box className="flex items-center gap-4 mb-4 bg-white rounded-md p-2">
              <HeaderImage src={img_palais} alt="Logo" />
              <Typography variant="h5" gutterBottom sx={{ mb: 0 }}>
                Le Palais
              </Typography>
            </Box>
          </Widget>
        </div>
      </div>

      <WallPaper />
    </Box>
  );
};

export default VinDegustation;
